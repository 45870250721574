import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { API_URL } from "../../api";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post(`${API_URL}/login`, credentials)
      .then(({ data }) => {
        if(data == "Your email address is not verified."){
          Swal.fire({
            title: "",
              text: "Please verify your email. If you have not received email yet, please check your spam folder. To receive email again, click the button below.",
              icon: "error",
              confirmButtonClass: "btn btn-primary mt-0",
              heightAuto: false,
              confirmButtonText: 'verify your email',
            }).then(function() {
              window.location = "/forgot";
          });
          }else{
            context.commit(SET_AUTH, data);
            resolve(data);
          }
          // console.log(data);
        })
        .catch(({ response }) => {
          // console.log(response.data.message);
          // console.log(response.data.message);
          if(response.data.message == "Your email address is not verified."){
            Swal.fire({
              title: "",
              text: "Please verify your email. If you have not received email yet, please check your spam folder. To receive email again, click the button below.",
              icon: "error",
              confirmButtonClass: "btn btn-primary mt-0",
              heightAuto: false,
              confirmButtonText: 'verify your email',
            }).then(function() {
              window.location = "/forgot";
          });
          }else{
            Swal.fire({
              title: "",
              text: "Please, provide correct data!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post(`${API_URL}/register`, credentials)
        .then(({ data }) => {
          // alert(data);
          if(data === "Record"){
            Swal.fire({
              title: "",
              text: "User alredy REGISTER please change your email adderss",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }else{
            if(data === "company"){
              Swal.fire({
                title: "",
                text: "Your company is already registered. Please use your existing account or contact us if you need any support.",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            }else{
              context.commit(SET_AUTH, data);
              resolve(data);
              Swal.fire({
                title: "",
                text: "Thanks for Registration..! Please check your email inbox or spam folder to verify your account.",
                icon: "success",
                confirmButtonClass: "btn btn-primary",
                href:"/login",
                heightAuto: false,
              }).then(function() {
                  window.location = "/login";
              });
            }
          }
          // console.log("despash page log");
        })
        .catch(({ response }) => {
          // Swal.fire({
          //   title: "",
          //   text: "User Alredy REGISTER",
          //   icon: "error",
          //   confirmButtonClass: "btn btn-secondary",
          //   heightAuto: false,
          // });
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put(`${API_URL}/change-password`, password)
    .then(
      ({ data }) => {
        console.log(data.message);
        if(data.message === "Please enter a password which is not similar then current password."){
          Swal.fire({
            title: "",
            text: "Please enter a password which is not similar then current password.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }else{
          if(data.message === "Check your old password."){
            Swal.fire({
              title: "",
              text: "Please Check your Current password.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }else{
            Swal.fire({
              title: "",
              text:  "Password updated successfully.",
              icon: "success",
              confirmButtonClass: "btn btn-primary",
              href:"/dashboard",
              heightAuto: false,
            }).then(function() {
                window.location = "/dashboard";
            });
          }
        }
      }
    );
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
