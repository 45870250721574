import Vue from "vue";
// import VueCompositionAPI from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueSkeletonLoader from "skeleton-loader-vue";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import { ToggleButton } from "vue-js-toggle-button";
import Vuelidate from "vuelidate";
import "vue-select/dist/vue-select.css";
// custom imports

import "../public/css/materialdesignicons.min.css";
import "swiper/swiper-bundle.css";
import { BootstrapVue } from "bootstrap-vue";
var VueScrollTo = require("vue-scrollto");
import Scrollspy from "vue2-scrollspy";
import VueYoutube from "vue-youtube";
import vueVimeoPlayer from "vue-vimeo-player";
import VueMasonry from "vue-masonry-css";
import "@/view/main.css";
import Skeleton from "vue-loading-skeleton";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import GoogleAuth from "@/core/config/google.js";
import VueSocialSharing from "vue-social-sharing";
import vSelect from "vue-select";
// API service init
ApiService.init();

// for slider
// Vue.use(VueCompositionAPI);

// Remove this to disable mock API
MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  next();
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.adminRequire)) {
    if (JSON.parse(localStorage.getItem("user")).role === "Super admin") {
      next();
    } else {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }

  if (to.matched.some((record) => record.meta.recruiterRequire)) {
    if (JSON.parse(localStorage.getItem("user")).role === "Direct Employer") {
      next();
    } else {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
//   next()
//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

Vue.use(BootstrapVue);
Vue.component("v-select", vSelect);
Vue.use(VueSocialSharing);
Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
);
Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease",
});
Vue.use(Scrollspy);
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(VueMasonry);
Vue.use(Skeleton);

const gauthOption = {
  clientId:
    "286261347495-9ggmbdb6hdutt2i98vrpi563bhlgc5pn.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(GoogleAuth, gauthOption);

Vue.component("ToggleButton", ToggleButton);
Vue.use(Vuelidate);
Vue.use(require("vue-moment"));
Vue.component("pagination", require("laravel-vue-pagination"));
Vue.component("vue-skeleton-loader", VueSkeletonLoader);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
