// export const API_URL = "http://localhost:8000/api";
// export const MEDIA_URL = "http://127.0.0.1:8000/storage/";

export const API_URL = "https://devapi.job4u.pk/api/api";
export const MEDIA_URL = "https://devapi.job4u.pk/api/storage/";

// export const API_URL = "https://stgapi.job4u.pk/api";
// export const MEDIA_URL = "https://stgapi.job4u.pk/api/storage/";

// export const API_URL = "https://api.job4u.pk/api";
// export const MEDIA_URL = "https://api.job4u.pk/api/storage/";
