import Vue from "vue";
import Router from "vue-router";
import VueRouter from "vue-router";
// abc
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/view/front/views/index-new"),
  },
  {
    path: "/job-sectors",
    name: "job-sectors",
    component: () => import("@/view/front/views/page-job-sector"),
  },
  {
    path: "/job-industrys",
    name: "job-industrys",
    component: () => import("@/view/front/views/page-job-industry"),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("@/view/front/views/page-about"),
  },
  {
    path: "/about-job4upk-jobs-in-Pakistan",
    name: "about_us",
    component: () => import("@/view/front/views/page_about_us"),
  },
  {
    path: "/jobs-in-pakistan",
    name: "jobs-in-pakistan",
    component: () => import("@/view/front/views/page-job-new"),
  },
  // comment
  {
    path: "/recruiter-directory",
    name: "recruiter-directory",
    component: () => import("@/view/front/views/page-recruiter-directory"),
  },
  {
    path: "/index-home",
    name: "index-home",
    component: () => import("@/view/front/views/page-index-home"),
  },
  {
    path: "/career-resources",
    name: "career-resources",
    component: () =>
      import("@/view/front/views/blog/main-page-blog/page-career-resources"),
  },
  {
    path: "/career-paths",
    name: "career-paths",
    component: () => import("@/view/front/views/blog/all-blog/career-paths"),
  },
  {
    path: "/networking-skills",
    name: "networking-skills",
    component: () =>
      import("@/view/front/views/blog/all-blog/networking-skills"),
  },
  {
    path: "/future-jobs",
    name: "future-jobs",
    component: () => import("@/view/front/views/blog/all-blog/future-jobs"),
  },
  {
    path: "/cv-advice",
    name: "cv-advice",
    component: () => import("@/view/front/views/blog/all-blog/cv-advice"),
  },
  {
    path: "/cover-letter-advice",
    name: "cover-letter-advice",
    component: () =>
      import("@/view/front/views/blog/all-blog/cover-letter-advice"),
  },
  {
    path: "/interview-preparation",
    name: "interview-preparation",
    component: () =>
      import("@/view/front/views/blog/all-blog/interview-preparation"),
  },
  {
    path: "/starting-a-new-job",
    name: "starting-a-new-job",
    component: () =>
      import("@/view/front/views/blog/all-blog/starting-a-new-job"),
  },
  {
    path: "/leaving-a-job",
    name: "leaving-a-job",
    component: () => import("@/view/front/views/blog/all-blog/leaving-a-job"),
  },
  {
    path: "/changing-careers",
    name: "changing-careers",
    component: () =>
      import("@/view/front/views/blog/all-blog/changing-careers"),
  },
  {
    path: "/advance-search",
    name: "advance-search",
    component: () => import("@/view/front/views/page-advanced-search"),
  },
  {
    path: "/index-saas",
    name: "Saas",
    component: () => import("@/view/front/views/index-saas"),
  },
  {
    path: "/index-classic-saas",
    name: "classic-saas",
    component: () => import("@/view/front/views/index-classic-saas"),
  },
  {
    path: "/index-agency",
    name: "agency",
    component: () => import("@/view/front/views/index-agency"),
  },
  {
    path: "/index-apps",
    name: "apps",
    component: () => import("@/view/front/views/index-apps"),
  },
  {
    path: "/index-classic-app",
    name: "classic-apps",
    component: () => import("@/view/front/views/index-classic-app"),
  },
  {
    path: "/index-studio",
    name: "studio",
    component: () => import("@/view/front/views/index-studio"),
  },
  {
    path: "/index-business",
    name: "business",
    component: () => import("@/view/front/views/index-business"),
  },
  {
    path: "/index-modern-business",
    name: "modern-business",
    component: () => import("@/view/front/views/index-modern-business"),
  },
  {
    path: "/index-hotel",
    name: "hotel",
    component: () => import("@/view/front/views/index-hotel"),
  },
  {
    path: "/index-marketing",
    name: "marketing",
    component: () => import("@/view/front/views/index-marketing"),
  },
  {
    path: "/index-enterprise",
    name: "enterprise",
    component: () => import("@/view/front/views/index-enterprise"),
  },
  {
    path: "/index-insurance",
    name: "insurance",
    component: () => import("@/view/front/views/index-insurance"),
  },
  {
    path: "/index-shop",
    name: "shop",
    component: () => import("@/view/front/views/index-shop"),
  },
  {
    path: "/index-coworking",
    name: "coworking",
    component: () => import("@/view/front/views/index-coworking"),
  },
  {
    path: "/index-it-solution",
    name: "it-solution",
    component: () => import("@/view/front/views/index-it-solution"),
  },
  {
    path: "/index-corporate",
    name: "corporate",
    component: () => import("@/view/front/views/index-corporate"),
  },
  {
    path: "/index-task-management",
    name: "task-management",
    component: () => import("@/view/front/views/index-task-management"),
  },
  {
    path: "/index-email-inbox",
    name: "email-inbox",
    component: () => import("@/view/front/views/index-email-inbox"),
  },
  {
    path: "/index-landing-one",
    name: "landing-one",
    component: () => import("@/view/front/views/index-landing-one"),
  },
  {
    path: "/index-landing-two",
    name: "landing-two",
    component: () => import("@/view/front/views/index-landing-two"),
  },
  {
    path: "/index-landing-three",
    name: "landing-three",
    component: () => import("@/view/front/views/index-landing-three"),
  },
  {
    path: "/index-travel",
    name: "travel",
    component: () => import("@/view/front/views/index-travel"),
  },
  {
    path: "/index-blog",
    name: "blog",
    component: () => import("@/view/front/views/index-blog"),
  },
  {
    path: "/forums",
    name: "forums",
    component: () => import("@/view/front/views/forums"),
  },
  {
    path: "/index-personal",
    name: "personal",
    component: () => import("@/view/front/views/index-personal"),
  },
  {
    path: "/index-services",
    name: "services",
    component: () => import("@/view/front/views/index-services"),
  },
  {
    path: "/index-payments",
    name: "payments",
    component: () => import("@/view/front/views/index-payments"),
  },
  {
    path: "/index-crypto",
    name: "crypto",
    component: () => import("@/view/front/views/index-crypto"),
  },
  {
    path: "/index-course",
    name: "course",
    component: () => import("@/view/front/views/index-course"),
  },
  {
    path: "/index-online-learning",
    name: "online-learning",
    component: () => import("@/view/front/views/index-online-learning"),
  },
  {
    path: "/index-hosting",
    name: "hosting",
    component: () => import("@/view/front/views/index-hosting"),
  },
  {
    path: "/index-event",
    name: "event",
    component: () => import("@/view/front/views/index-event"),
  },
  {
    path: "/index-single-product",
    name: "single-product",
    component: () => import("@/view/front/views/index-single-product"),
  },
  {
    path: "/index-portfolio",
    name: "portfolio",
    component: () => import("@/view/front/views/index-portfolio"),
  },
  {
    path: "/index-job",
    name: "job",
    component: () => import("@/view/front/views/index-job"),
  },
  {
    path: "/index-social-marketing",
    name: "social-marketing",
    component: () => import("@/view/front/views/index-social-marketing"),
  },
  {
    path: "/index-digital-agency",
    name: "digital-agency",
    component: () => import("@/view/front/views/index-digital-agency"),
  },
  {
    path: "/index-car-riding",
    name: "car-riding",
    component: () => import("@/view/front/views/index-car-riding"),
  },
  {
    path: "/index-customer",
    name: "customer",
    component: () => import("@/view/front/views/index-customer"),
  },
  {
    path: "/index-software",
    name: "software",
    component: () => import("@/view/front/views/index-software"),
  },
  {
    path: "/index-ebook",
    name: "ebook",
    component: () => import("@/view/front/views/index-ebook"),
  },
  {
    path: "/index-onepage",
    name: "onepage",
    component: () => import("@/view/front/views/index-onepage"),
  },
  {
    path: "/page-aboutus",
    name: "aboutus",
    component: () => import("@/view/front/views/page-aboutus"),
  },
  {
    path: "/page-aboutus-two",
    name: "aboutus-two",
    component: () => import("@/view/front/views/page-aboutus-two"),
  },
  {
    path: "/recruit-top-talent-in-pakistan",
    name: "recruit_top_talent_in_pakistan",
    component: () => import("@/view/front/views/plan"),
  },
  {
    path: "/page-services",
    name: "page-services",
    component: () => import("@/view/front/views/page-services"),
  },
  {
    path: "/page-history",
    name: "history",
    component: () => import("@/view/front/views/page-history"),
  },
  {
    path: "/page-team",
    name: "team",
    component: () => import("@/view/front/views/page-team"),
  },
  {
    path: "/page-pricing",
    name: "pricing",
    component: () => import("@/view/front/views/page-pricing"),
  },
  {
    path: "/account-profile",
    name: "account-profile",
    component: () => import("@/view/front/views/account-profile"),
  },
  {
    path: "/account-members",
    name: "account-members",
    component: () => import("@/view/front/views/account-members"),
  },
  {
    path: "/account-works",
    name: "account-works",
    component: () => import("@/view/front/views/account-works"),
  },
  {
    path: "/account-messages",
    name: "account-messages",
    component: () => import("@/view/front/views/account-messages"),
  },
  {
    path: "/account-payments",
    name: "account-payments",
    component: () => import("@/view/front/views/account-payments"),
  },
  {
    path: "/account-setting",
    name: "account-setting",
    component: () => import("@/view/front/views/account-setting"),
  },
  {
    path: "/page-invoice",
    name: "page-invoice",
    component: () => import("@/view/front/views/page-invoice"),
  },
  {
    path: "/shop-products",
    name: "shop-products",
    component: () => import("@/view/front/views/shop-products"),
  },
  {
    path: "/shop-product-detail",
    name: "shop-product-detail",
    component: () => import("@/view/front/views/shop-product-detail"),
  },
  {
    path: "/shop-cart",
    name: "shop-cart",
    component: () => import("@/view/front/views/shop-cart"),
  },
  {
    path: "/shop-checkouts",
    name: "shop-checkouts",
    component: () => import("@/view/front/views/shop-checkouts"),
  },
  {
    path: "/shop-myaccount",
    name: "shop-myaccount",
    component: () => import("@/view/front/views/shop-myaccount"),
  },
  {
    path: "/helpcenter-overview",
    name: "helpcenter-overview",
    component: () => import("@/view/front/views/helpcenter-overview"),
  },
  {
    path: "/helpcenter-faqs",
    name: "helpcenter-faqs",
    component: () => import("@/view/front/views/helpcenter-faqs"),
  },
  {
    path: "/helpcenter-guides",
    name: "helpcenter-guides",
    component: () => import("@/view/front/views/helpcenter-guides"),
  },
  {
    path: "/helpcenter-support-request",
    name: "helpcenter-support-request",
    component: () => import("@/view/front/views/helpcenter-support-request"),
  },
  {
    path: "/forums-topic",
    name: "forums-topic",
    component: () => import("@/view/front/views/forums-topic"),
  },
  {
    path: "/forums-comments",
    name: "forums-comments",
    component: () => import("@/view/front/views/forums-comments"),
  },
  {
    path: "/email-alert",
    name: "email-alert",
    component: () => import("@/view/front/views/email-alert"),
  },
  {
    path: "/email-invoice",
    name: "email-invoice",
    component: () => import("@/view/front/views/email-invoice"),
  },
  {
    path: "/email-confirmation",
    name: "email-confirmation",
    component: () => import("@/view/front/views/email-confirmation"),
  },
  {
    path: "/email-password-reset",
    name: "email-password-reset",
    component: () => import("@/view/front/views/email-password-reset"),
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("@/view/front/views/page-jobs"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/view/front/views/page-jobs-search"),
  },
  {
    path: "/jobs/Pakistan/:city",
    name: "city-jobs",
    component: () => import("@/view/front/views/page-jobs-city"),
  },

  {
    path: "/jobs-by-sectors",
    name: "jobs-by-sectors",
    component: () => import("@/view/front/views/page-jobs-by-sectors"),
  },
  {
    path: "/jobs-careers-pakistan/jobs-sector/:sector",
    name: "jobs",
    component: () => import("@/view/front/views/page-sector-jobs"),
  },
  {
    path: "/jobs-careers-pakistan/jobs-industry/:industry",
    name: "jobs-industry",
    component: () => import("@/view/front/views/page-jobs-industrys"),
  },
  {
    path: "/jobs-careers/apply/:country/:cities/:title/:id",
    name: "job-apply",
    component: () => import("@/view/front/views/page-job-apply"),
  },
  {
    path: "/page-job-candidate",
    name: "job-candidate",
    component: () => import("@/view/front/views/page-job-candidate"),
  },
  {
    path: "/page-job-company",
    name: "job-company",
    component: () => import("@/view/front/views/page-job-company"),
  },
  {
    path: "/recruiter",
    name: "recruiter",
    component: () => import("@/view/front/views/page-recruiter"),
  },
  {
    path: "/jobs-careers/:country/:cities/:title/:id",
    name: "job-detail",
    component: () => import("@/view/front/views/page-job-detail"),
  },
  {
    path: "/employer/:title",
    name: "job-company",
    component: () => import("@/view/front/views/page-job-company"),
  },
  {
    path: "/page-jobs-sidebar",
    name: "jobs-sidebar",
    component: () => import("@/view/front/views/page-jobs-sidebar"),
  },
  {
    path: "/page-blog-grid",
    name: "blog-grid",
    component: () => import("@/view/front/views/page-blog-grid"),
  },
  {
    path: "/page-blog-sidebar",
    name: "blog-sidebar",
    component: () => import("@/view/front/views/page-blog-sidebar"),
  },
  {
    path: "/page-blog-list",
    name: "blog-list",
    component: () => import("@/view/front/views/page-blog-list"),
  },
  {
    path: "/page-blog-list-sidebar",
    name: "blog-list-sidebar",
    component: () => import("@/view/front/views/page-blog-list-sidebar"),
  },
  {
    path: "/page-blog-detail",
    name: "blog-detail",
    component: () => import("@/view/front/views/page-blog-detail"),
  },
  {
    path: "/page-blog-detail-two",
    name: "blog-detail-two",
    component: () => import("@/view/front/views/page-blog-detail-two"),
  },
  {
    path: "/page-cases",
    name: "cases",
    component: () => import("@/view/front/views/page-cases"),
  },
  {
    path: "/page-case-detail",
    name: "case-detail",
    component: () => import("@/view/front/views/page-case-detail"),
  },
  {
    path: "/page-work-detail",
    name: "work-detail",
    component: () => import("@/view/front/views/page-work-detail"),
  },
  {
    path: "/page-work-modern",
    name: "work-modern",
    component: () => import("@/view/front/views/page-work-modern"),
  },
  {
    path: "/page-work-classic",
    name: "work-classic",
    component: () => import("@/view/front/views/page-work-classic"),
  },
  {
    path: "/page-work-grid",
    name: "work-grid",
    component: () => import("@/view/front/views/page-work-grid"),
  },
  {
    path: "/page-work-masonry",
    name: "work-masonry",
    component: () => import("@/view/front/views/page-work-masonry"),
  },
  // {
  //   path: '/auth-login',
  //   name: 'login',
  //   component: () => import('@/view/front/views/auth-login')
  // },
  {
    path: "/auth-signup",
    name: "signup",
    component: () => import("@/view/front/views/auth-signup"),
  },
  {
    path: "/auth-re-password",
    name: "reset-password",
    component: () => import("@/view/front/views/auth-re-password"),
  },
  {
    path: "/auth-cover-login",
    name: "cover-login",
    component: () => import("@/view/front/views/auth-cover-login"),
  },
  {
    path: "/auth-cover-signup",
    name: "cover-signup",
    component: () => import("@/view/front/views/auth-cover-signup"),
  },
  {
    path: "/auth-cover-re-password",
    name: "cover-reset-password",
    component: () => import("@/view/front/views/auth-cover-re-password"),
  },
  {
    path: "/auth-login-three",
    name: "login-three",
    component: () => import("@/view/front/views/auth-login-three"),
  },
  {
    path: "/auth-signup-three",
    name: "signup-three",
    component: () => import("@/view/front/views/auth-signup-three"),
  },
  {
    path: "/auth-re-password-three",
    name: "reset-password-three",
    component: () => import("@/view/front/views/auth-re-password-three"),
  },
  {
    path: "/page-privacy",
    name: "privacy",
    component: () => import("@/view/front/views/page-privacy"),
  },
  {
    path: "/page-terms",
    name: "terms",
    component: () => import("@/view/front/views/page-terms"),
  },
  {
    path: "/page-comingsoon",
    name: "comingsoon",
    component: () => import("@/view/front/views/page-comingsoon"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () => import("@/view/front/views/terms-and-conditions"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/view/front/views/privacy-policy"),
  },
  {
    path: "/page-maintenance",
    name: "maintenance",
    component: () => import("@/view/front/views/page-maintenance"),
  },
  {
    path: "/page-error",
    name: "error",
    component: () => import("@/view/front/views/page-error"),
  },
  {
    path: "/page-contact-detail",
    name: "contact-detail",
    component: () => import("@/view/front/views/page-contact-detail"),
  },
  {
    path: "/contact-us",
    name: "contact-one",
    component: () => import("@/view/front/views/page-contact-one"),
  },
  {
    path: "/page-contact-two",
    name: "contact-two",
    component: () => import("@/view/front/views/page-contact-two"),
  },
  {
    path: "/page-contact-three",
    name: "contact-three",
    component: () => import("@/view/front/views/page-contact-three"),
  },
  {
    path: "/documentation",
    name: "documentation",
    component: () => import("@/view/front/views/documentation"),
  },
  {
    path: "/changelog",
    name: "changelog",
    component: () => import("@/view/front/views/changelog"),
  },
  {
    path: "/widget",
    name: "widget",
    component: () => import("@/view/front/views/widget"),
  },
  {
    path: "/components",
    name: "components",
    component: () => import("@/view/front/views/components"),
  },
  {
    path: "/auth",
    redirect: "/login",
    name: "dashboardLogin",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
      },
      {
        path: "/admin/settings/countries",
        name: "countries",
        component: () => import("@/view/pages/admin/countries/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/states",
        name: "states",
        component: () => import("@/view/pages/admin/states/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/cities",
        name: "cities",
        component: () => import("@/view/pages/admin/cities/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/gender",
        name: "gender",
        component: () => import("@/view/pages/admin/gender/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/news-alert",
        name: "news-alert",
        component: () => import("@/view/pages/admin/news-alert/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/gender",
        name: "gender",
        component: () => import("@/view/pages/admin/gender/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/industry",
        name: "industry",
        component: () => import("@/view/pages/admin/industry/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/cities",
        name: "cities",
        component: () => import("@/view/pages/admin/cities/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/education/qualification",
        name: "qualification",
        component: () =>
          import("@/view/pages/admin/education/qualification/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/education/degree",
        name: "degree",
        component: () =>
          import("@/view/pages/admin/education/degree/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/education/degree-subject",
        name: "degree-subject",
        component: () =>
          import("@/view/pages/admin/education/degree-subject/index.vue"),
        meta: { adminRequire: true },
      },

      {
        path: "/admin/settings/education/universities",
        name: "universities",
        component: () =>
          import("@/view/pages/admin/education/universities/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/profession/career-level",
        name: "career-level",
        component: () =>
          import(
            "@/view/pages/admin/profession/profession/career-level/index.vue"
          ),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/profession/profession",
        name: "profession",
        component: () =>
          import("@/view/pages/admin/profession/profession/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/profession/skill",
        name: "profession",
        component: () =>
          import("@/view/pages/admin/profession/profession/skill.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/job/job-function",
        name: "job-function",
        component: () =>
          import("@/view/pages/admin/job/job-function/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/job/job-type",
        name: "job-type",
        component: () => import("@/view/pages/admin/job/job-type/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/job/job-shift",
        name: "job-shift",
        component: () => import("@/view/pages/admin/job/job-shift/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/job/minimum-salary",
        name: "minimum-salary",
        component: () =>
          import("@/view/pages/admin/job/minimum-salary/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/job/maximum-salary",
        name: "maximum-salary",
        component: () =>
          import("@/view/pages/admin/job/maximum-salary/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/users-management/users",
        name: "users",
        component: () =>
          import("@/view/pages/admin/users-management/users/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/companies-management",
        name: "companies-management",
        component: () =>
          import("@/view/pages/admin/users-management/company/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/users-management/users-restore",
        name: "users-restore",
        component: () =>
          import("@/view/pages/admin/users-management/restore/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/jobs",
        name: "jobs",
        component: () => import("@/view/pages/admin/jobs/index.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/jobs/create",
        name: "job-create",
        component: () => import("@/view/pages/admin/jobs/create.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/settings/jobs/edit",
        name: "job-edit",
        component: () => import("@/view/pages/admin/jobs/edit.vue"),
        meta: { adminRequire: true },
      },
      {
        path: "/admin/bulk-message",
        name: "bulk-message",
        component: () => import("@/view/pages/admin/message/index.vue"),
        meta: { adminRequire: true },
      },

      // recruiter routes
      {
        path: "/recruiter/jobs",
        name: "recruiter-jobs",
        component: () => import("@/view/pages/recruiter/jobs/index.vue"),
        meta: { recruiterRequire: true },
      },
      {
        path: "/recruiter/jobs/create",
        name: "recruiter-job-create",
        component: () => import("@/view/pages/recruiter/jobs/create.vue"),
        meta: { recruiterRequire: true },
      },
      {
        path: "/recruiter/jobs/edit",
        name: "recruiter-job-edit",
        component: () => import("@/view/pages/recruiter/jobs/edit.vue"),
        meta: { recruiterRequire: true },
      },
      {
        path: "/recruiter/profile",
        name: "recruiter-edit-profile",
        component: () => import("@/view/pages/recruiter/profile/profile.vue"),
        meta: { recruiterRequire: true },
      },
      {
        path: "/recruiter/job-applications",
        name: "recruiter-job-applications",
        component: () =>
          import("@/view/pages/recruiter/job-applications/index.vue"),
        meta: { recruiterRequire: true },
      },
      {
        path: "/recruiter/job-application-detail/:id",
        name: "recruiter-job-application-detail",
        component: () =>
          import(
            "@/view/pages/recruiter/job-applications/job-application-details.vue"
          ),
        meta: { recruiterRequire: true },
      },
      {
        path: "/recruiter/cv-search",
        name: "recruiter-cv-search",
        component: () => import("@/view/pages/recruiter/cv-search.vue"),
        meta: { recruiterRequire: true },
      },
      {
        path: "/job-seeker-employability-profile/:id",
        name: "/job-seeker-employability-profile/:id",
        component: () => import("@/view/pages/recruiter/cv_profile.vue"),
      },
      {
        path: "/recruiter/shortlisted-candidates",
        name: "recruiter-shortlisted-candidates",
        component: () => import("@/view/pages/recruiter/short-list.vue"),
        meta: { recruiterRequire: true },
      },
      {
        path: "/user/inbox",
        name: "user-inbox",
        component: () => import("@/view/pages/common/inbox.vue"),
      },

      // job seeker routes
      {
        path: "/job-seeker/profile",
        name: "job-seeker-edit-profile",
        component: () => import("@/view/pages/job-seeker/profile/profile.vue"),
      },
      {
        path: "/job-seeker/cv-manager",
        name: "job-seeker-cv-manager",
        component: () => import("@/view/pages/job-seeker/cv-manager/index.vue"),
      },
      {
        path: "/job-seeker/intro-videos",
        name: "job-seeker-intro-videos",
        component: () =>
          import("@/view/pages/job-seeker/intro-videos/index.vue"),
      },
      {
        path: "/job-seeker/intro-audio",
        name: "job-seeker-intro-audio",
        component: () =>
          import("@/view/pages/job-seeker/intro-audio/index.vue"),
      },
      {
        path: "/job-seeker/my-job-applications",
        name: "my-job-applications",
        component: () =>
          import("@/view/pages/job-seeker/job-applications/index.vue"),
      },
      {
        path: "/job-seeker/saved-jobs",
        name: "job-seeker-saved-jobs",
        component: () => import("@/view/pages/job-seeker/saved-jobs/index.vue"),
      },

      {
        path: "/auth/github/callback",
        component: {
          template: '<div class="auth-component"></div>',
        },
      },

      {
        path: "/builder",
        name: "builder",
        component: () => import("@/view/pages/Builder.vue"),
      },
      {
        path: "/vue-bootstrap",
        name: "vue-bootstrap",
        component: () => import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
        children: [
          {
            path: "alert",
            name: "vue-bootstrap-alert",
            component: () => import("@/view/pages/vue-bootstrap/Alert.vue"),
          },
          {
            path: "badge",
            name: "vue-bootstrap-badge",
            component: () => import("@/view/pages/vue-bootstrap/Badge.vue"),
          },
          {
            path: "button",
            name: "vue-bootstrap-button",
            component: () => import("@/view/pages/vue-bootstrap/Button.vue"),
          },
          {
            path: "button-group",
            name: "vue-bootstrap-button-group",
            component: () =>
              import("@/view/pages/vue-bootstrap/ButtonGroup.vue"),
          },
          {
            path: "button-toolbar",
            name: "vue-bootstrap-button-toolbar",
            component: () =>
              import("@/view/pages/vue-bootstrap/ButtonToolbar.vue"),
          },
          {
            path: "card",
            name: "vue-bootstrap-card",
            component: () => import("@/view/pages/vue-bootstrap/Card.vue"),
          },
          {
            path: "carousel",
            name: "vue-bootstrap-carousel",
            component: () => import("@/view/pages/vue-bootstrap/Carousel.vue"),
          },
          {
            path: "collapse",
            name: "vue-bootstrap-collapse",
            component: () => import("@/view/pages/vue-bootstrap/Collapse.vue"),
          },
          {
            path: "dropdown",
            name: "vue-bootstrap-dropdown",
            component: () => import("@/view/pages/vue-bootstrap/Dropdown.vue"),
          },
          {
            path: "embed",
            name: "vue-bootstrap-embed",
            component: () => import("@/view/pages/vue-bootstrap/Embed.vue"),
          },
          {
            path: "form",
            name: "vue-bootstrap-form",
            component: () => import("@/view/pages/vue-bootstrap/Form.vue"),
          },
          {
            path: "form-checkbox",
            name: "vue-bootstrap-form-checkbox",
            component: () =>
              import("@/view/pages/vue-bootstrap/FormCheckbox.vue"),
          },
          {
            path: "form-file",
            name: "vue-bootstrap-form-file",
            component: () => import("@/view/pages/vue-bootstrap/FormFile.vue"),
          },
          {
            path: "form-group",
            name: "vue-bootstrap-form-group",
            component: () => import("@/view/pages/vue-bootstrap/FormGroup.vue"),
          },
          {
            path: "form-input",
            name: "vue-bootstrap-form-input",
            component: () => import("@/view/pages/vue-bootstrap/FormInput.vue"),
          },
          {
            path: "form-radio",
            name: "vue-bootstrap-form-radio",
            component: () => import("@/view/pages/vue-bootstrap/FormRadio.vue"),
          },
          {
            path: "form-select",
            name: "vue-bootstrap-form-select",
            component: () =>
              import("@/view/pages/vue-bootstrap/FormSelect.vue"),
          },
          {
            path: "form-textarea",
            name: "vue-bootstrap-form-textarea",
            component: () =>
              import("@/view/pages/vue-bootstrap/FormTextarea.vue"),
          },
          {
            path: "image",
            name: "vue-bootstrap-image",
            component: () => import("@/view/pages/vue-bootstrap/Image.vue"),
          },
          {
            path: "input-group",
            name: "vue-bootstrap-input-group",
            component: () =>
              import("@/view/pages/vue-bootstrap/InputGroup.vue"),
          },
          {
            path: "jumbotron",
            name: "vue-bootstrap-jumbotron",
            component: () => import("@/view/pages/vue-bootstrap/Jumbotron.vue"),
          },
          {
            path: "layout-grid-system",
            name: "vue-bootstrap-layout-grid-system",
            component: () =>
              import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue"),
          },
          {
            path: "link",
            name: "vue-bootstrap-link",
            component: () => import("@/view/pages/vue-bootstrap/Link.vue"),
          },
          {
            path: "list-group",
            name: "vue-bootstrap-list-group",
            component: () => import("@/view/pages/vue-bootstrap/ListGroup.vue"),
          },
          {
            path: "media",
            name: "vue-bootstrap-media",
            component: () => import("@/view/pages/vue-bootstrap/Media.vue"),
          },
          {
            path: "modal",
            name: "vue-bootstrap-modal",
            component: () => import("@/view/pages/vue-bootstrap/Modal.vue"),
          },
          {
            path: "nav",
            name: "vue-bootstrap-nav",
            component: () => import("@/view/pages/vue-bootstrap/Nav.vue"),
          },
          {
            path: "navbar",
            name: "vue-bootstrap-navbar",
            component: () => import("@/view/pages/vue-bootstrap/Navbar.vue"),
          },
          {
            path: "pagination",
            name: "vue-bootstrap-pagination",
            component: () =>
              import("@/view/pages/vue-bootstrap/Pagination.vue"),
          },
          {
            path: "pagination-nav",
            name: "vue-bootstrap-pagination-nav",
            component: () =>
              import("@/view/pages/vue-bootstrap/PaginationNav.vue"),
          },
          {
            path: "notify-popover",
            name: "vue-bootstrap-popover",
            component: () => import("@/view/pages/vue-bootstrap/Popover.vue"),
          },
          {
            path: "notify-toasts",
            name: "vue-bootstrap-toasts",
            component: () => import("@/view/pages/vue-bootstrap/Toasts.vue"),
          },
          {
            path: "notify-tooltip",
            name: "vue-bootstrap-tooltip",
            component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue"),
          },
          {
            path: "progress",
            name: "vue-bootstrap-progress",
            component: () => import("@/view/pages/vue-bootstrap/Progress.vue"),
          },
          {
            path: "progress-spinner",
            name: "vue-bootstrap-spinner",
            component: () => import("@/view/pages/vue-bootstrap/Spinner.vue"),
          },
          {
            path: "table",
            name: "vue-bootstrap-table",
            component: () => import("@/view/pages/vue-bootstrap/Table.vue"),
          },
          {
            path: "tabs",
            name: "vue-bootstrap-tabs",
            component: () => import("@/view/pages/vue-bootstrap/Tabs.vue"),
          },
        ],
      },
      {
        path: "/vuetify",
        name: "vuetify",
        component: () => import("@/view/pages/vuetify/Vuetify.vue"),
        children: [
          {
            path: "alerts",
            name: "vuetify-alerts",
            component: () => import("@/view/pages/vuetify/Alerts.vue"),
          },
          {
            path: "avatars",
            name: "vuetify-avatars",
            component: () => import("@/view/pages/vuetify/Avatars.vue"),
          },
          {
            path: "badges",
            name: "vuetify-badges",
            component: () => import("@/view/pages/vuetify/Badges.vue"),
          },
          {
            path: "buttons",
            name: "vuetify-buttons",
            component: () => import("@/view/pages/vuetify/Buttons.vue"),
          },
          {
            path: "calendars",
            name: "vuetify-calendars",
            component: () => import("@/view/pages/vuetify/Calendars.vue"),
          },
          {
            path: "cards",
            name: "vuetify-cards",
            component: () => import("@/view/pages/vuetify/Cards.vue"),
          },
          {
            path: "chips",
            name: "vuetify-chips",
            component: () => import("@/view/pages/vuetify/Chips.vue"),
          },
          {
            path: "dialog",
            name: "vuetify-dialog",
            component: () => import("@/view/pages/vuetify/Dialog.vue"),
          },
          {
            path: "forms-autocompletes",
            name: "vuetify-autocompletes",
            component: () =>
              import("@/view/pages/vuetify/forms/Autocompletes.vue"),
          },
          {
            path: "forms-file-inputs",
            name: "vuetify-file-inputs",
            component: () =>
              import("@/view/pages/vuetify/forms/FileInputs.vue"),
          },
          {
            path: "forms",
            name: "vuetify-forms",
            component: () => import("@/view/pages/vuetify/forms/Forms.vue"),
          },
          {
            path: "forms-selection-controls",
            name: "vuetify-selection-controls",
            component: () =>
              import("@/view/pages/vuetify/forms/SelectionControls.vue"),
          },
          {
            path: "forms-selects",
            name: "vuetify-selects",
            component: () => import("@/view/pages/vuetify/forms/Selects.vue"),
          },
          {
            path: "forms-textareas",
            name: "vuetify-textareas",
            component: () => import("@/view/pages/vuetify/forms/Textareas.vue"),
          },
          {
            path: "forms-text-fields",
            name: "vuetify-text-fields",
            component: () =>
              import("@/view/pages/vuetify/forms/TextFields.vue"),
          },
          {
            path: "tables-simple-tables",
            name: "vuetify-simple-tables",
            component: () =>
              import("@/view/pages/vuetify/tables/SimpleTables.vue"),
          },
          {
            path: "tables-data-tables",
            name: "vuetify-data-tables",
            component: () =>
              import("@/view/pages/vuetify/tables/DataTables.vue"),
          },
          {
            path: "tabs",
            name: "vuetify-tabs",
            component: () => import("@/view/pages/vuetify/Tabs.vue"),
          },
          {
            path: "timelines",
            name: "vuetify-timelines",
            component: () => import("@/view/pages/vuetify/Timelines.vue"),
          },
          {
            path: "tooltips",
            name: "vuetify-tooltips",
            component: () => import("@/view/pages/vuetify/Tooltips.vue"),
          },
          {
            path: "treeview",
            name: "vuetify-treeview",
            component: () => import("@/view/pages/vuetify/Treeview.vue"),
          },
        ],
      },
      {
        path: "/custom-wizard",
        name: "wizard",
        component: () => import("@/view/pages/wizard/Wizard.vue"),
        children: [
          {
            path: "wizard-1",
            name: "wizard-1",
            component: () => import("@/view/pages/wizard/Wizard-1.vue"),
          },
          {
            path: "wizard-2",
            name: "wizard-2",
            component: () => import("@/view/pages/wizard/Wizard-2.vue"),
          },
          {
            path: "wizard-3",
            name: "wizard-3",
            component: () => import("@/view/pages/wizard/Wizard-3.vue"),
          },
          {
            path: "wizard-4",
            name: "wizard-4",
            component: () => import("@/view/pages/wizard/Wizard-4.vue"),
          },
        ],
      },
      {
        path: "/custom-plugins",
        name: "plugins",
        component: () => import("@/view/pages/plugins/Plugins.vue"),
        children: [
          {
            path: "cropper",
            name: "cropper",
            component: () => import("@/view/pages/plugins/Cropper.vue"),
          },
          {
            path: "treeselect",
            name: "treeselect",
            component: () => import("@/view/pages/plugins/Treeselect.vue"),
          },
        ],
      },
      {
        path: "/custom-pages",
        name: "custom-pages",
        component: () => import("@/view/pages/custom_pages/CustomPages.vue"),
        children: [
          {
            path: "profile",
            name: "profile",
            component: () => import("@/view/pages/custom_pages/Profile.vue"),
          },
        ],
      },
      {
        path: "/change-password",
        name: "change-password",
        component: () =>
          import("@/view/pages/custom_pages/profile-comp/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/custom-error",
    name: "error",
    component: () => import("@/view/pages/error/Error.vue"),
    children: [
      {
        path: "error-1",
        name: "error-1",
        component: () => import("@/view/pages/error/Error-1.vue"),
      },
      {
        path: "error-2",
        name: "error-2",
        component: () => import("@/view/pages/error/Error-2.vue"),
      },
      {
        path: "error-3",
        name: "error-3",
        component: () => import("@/view/pages/error/Error-3.vue"),
      },
      {
        path: "error-4",
        name: "error-4",
        component: () => import("@/view/pages/error/Error-4.vue"),
      },
      {
        path: "error-5",
        name: "error-5",
        component: () => import("@/view/pages/error/Error-5.vue"),
      },
      {
        path: "error-6",
        name: "error-6",
        component: () => import("@/view/pages/error/Error-6.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/view/pages/auth/login_pages/Login-1"),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("@/view/pages/auth/login_pages/forgot"),
  },
  {
    path: "/set-password",
    name: "set-password",
    component: () => import("@/view/pages/auth/login_pages/password-reset"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/view/pages/auth/login_pages/register"),
  },
  {
    path: "/recruiter-registeration",
    name: "recruiter-registeration",
    component: () =>
      import("@/view/pages/auth/login_pages/recruiter-registeration"),
  },
  {
    path: "/job-seeker-register",
    name: "job-seeker-register",
    component: () =>
      import("@/view/pages/auth/login_pages/job-seeker-register"),
  },
  {
    path: "/register_",
    name: "register_",
    component: () => import("@/view/pages/auth/register_"),
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-1.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
